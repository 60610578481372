<template>
  <div v-if="helpVideo">
    <iframe :width="width" :height="height" :src="getVideoUrl()" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="" title="ECAT eCommerce"/>
  </div>
  <div v-else-if="loading">
    <div class="text-center py-3">
      <h5>{{ $t('message.loading') }}</h5>
      <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "help-video-render",

  data() {
    return {
      helpVideo: null,
      loading: false
    }
  },

  props: {
    height: {
      type: String,
    },
    width: {
      type: String,
    }
  },

  methods: {
    async loadHelpVideo(path) {
      this.loading = true
      this.helpVideo = null

      if (path === "/") {
        return
      }

      try {
        const {data} = await axios.get(`/help-video`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          params: {
            languageId: this.$store.getters["translation/getCurrentLocale"],
            path: decodeURIComponent(path)
          }
        })

        this.helpVideo = data
      } catch (error) {
        this.loading = false
      }
    },

    getVideoUrl() {
      if (!this.helpVideo) {
        return ""
      }

      switch (this.helpVideo.videoType) {
        case "VIMEO":
          return `https://player.vimeo.com/video/${this.helpVideo.videoId}`
        case "YOUTUBE":
          return `https://www.youtube.com/embed/${this.helpVideo.videoId}`
      }

      return ""
    }
  },

  watch: {
    $route: {
      immediate: true,
      async handler(to) {
        await this.loadHelpVideo(to.path)
      }
    }
  },

  mounted() {
    this.$root.$on("change-language", async () => await this.loadHelpVideo(this.$route.path))
  },

  beforeDestroy() {
    this.$root.$off("change-language");
  },

}
</script>